.ssh {
  display: flex;
}

.sshCommand {
  border: 1px solid var(--divider);
  border-radius: 4px;
  font-size: 0.75rem;
  background-color: var(--background);
  padding: 0.25rem 0.25rem 0 0.25rem;
}
