.container {
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: 'b b b b b b';
}

@media (min-width: 1024px) {
  .container {
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(3, minmax(130px, 1fr)) repeat(9, 1fr);
    grid-template-areas: 'a a b b b b b b b c c c';
  }
}
