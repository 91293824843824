.table {
  padding-top: 24px;
  font-weight: 500;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1em;
}

.table,
.table th,
.table td {
  border-bottom: 1px solid var(--divider);
  padding: 12px;
}

.table tr {
  height: 60px;
}

@media (min-width: 1024px) {
  .table tr > .select > input {
    visibility: hidden;
  }
}

.table tr:hover > td {
  color: var(--primary);
}

.table .selectedRow > .select > input {
  visibility: visible;
}

.table tr:hover > .select > input {
  visibility: visible;
}

.table th {
  font-size: var(--subtitle);
  color: var(--dimText);
  width: 200px;
  white-space: nowrap;
}

.headers {
  height: 20px;
  text-align: left;
}

.selectedRow {
  background-color: var(--selectedRow);
}

.table .select {
  width: 25px;
  text-align: center;
}

.table .fileType {
  width: 25px;
}

.table .action {
  width: 20px;
}

.table .contentLength {
  width: 100px;
  text-align: center;
}

.table input[type=checkbox] {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.table input[type=checkbox]:active,
input[type=checkbox]:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.table input[type=checkbox]:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.table input[type=checkbox]:checked::after {
  content: '\2714';
  position: absolute;
  top: 0;
  left: 3px;
  color: var(--primary);
  font-size: var(--body);
}

.table input[type=checkbox]:focus {
  border: 1px solid var(--primary);
  box-shadow: 0 0 0 3px var(--shadow);
  outline: none;
}

.centered {
  display: flex;
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.clickable svg {
  fill: var(--secondary);
}

.clickable:hover svg {
  fill: var(--primary);
}
