.content {
  position: relative;
  grid-area: b;
  text-align: left;
  padding: 1em;
  padding-top: 0;
  font-size: var(--body);
}

@media (min-width: 1024px) {
  .content {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
