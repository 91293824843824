.column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  scrollbar-width: none;
}

.column::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: center;
}

.details {
  width: calc(100% - 4em);
  display: flex;
  flex-direction: column;
  text-align: left;
}

.flexCentered {
  display: flex;
  align-items: center;
}

.filename {
  max-width: 250px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.description {
  box-sizing: border-box;
  border: 1px solid var(--divider);
  padding: 8px;
  border-radius: 4px;
  resize: none;
  font-size: var(--body);
  color: var(--text);
}

.description:focus {
  border: 1px solid var(--primary);
  outline: none;
}

.clickable {
  cursor: pointer;
}

.clickable:hover::after {
  position: absolute;
  content: ' ';
  margin-left: 6px;
  background-image: url('./icons/restore.svg');
  background-size: 1em 1em;
  height: 1em;
  width: 1em;
}

.altName {
  margin: 0;
  font-size: var(--h5);
  text-align: center;
}

.icon {
  margin-right: 0.5em;
}

.longText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.renameable {
  text-align: center;
}

.renameable:hover,
.resizeable:hover {
  color: var(--primary);
  cursor: pointer;
}

.renameable:hover::after,
.resizeable:hover::after {
  position: absolute;
  display: inline-block;
  content: ' ';
  margin-left: 0.5em;
  background-image: url('./icons/rename-active.svg');
  background-size: 0.85em 0.85em;
  height: 0.85em;
  width: 0.85em;
  margin-top: 4px;
}

.resizeable:hover::after {
  margin-top: 2px;
}

.statusMessage {
  margin-top: 1em;
}

.vmImageField {
  margin-top: 0.5em;
}
