.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex > div {
  margin-right: 2em;
}

.flex > div:last-child {
  margin-right: 1em;
}

.flexCentered {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.description {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1em;
}

.empty a {
  color: var(--primary);
}

.showAll {
  border: 0;
  background-color: transparent;
  color: var(--primary);
}
