.logout {
  background-color: transparent;
  border: 0;
  color: var(--lightText);
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  text-align: left;
  align-items: center;
}

.logout > svg {
  fill: var(--lightText);
  margin-right: 0.5em;
}

.logout:hover {
  color: var(--highlight);
}

.logout:hover > svg {
  fill: var(--highlight);
}
