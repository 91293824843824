.text {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.copy {
  background-image: url('./icons/clipboard.svg');
  background-position: 50%, 50%;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  border: 0;
  background-color: transparent;
  margin: 0;
  margin-left: 12px;
  cursor: pointer;
  visibility: hidden;
}

.copy:focus {
  outline: none;
}

.text:hover > .copy {
  visibility: visible;
}
