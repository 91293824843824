.page {
  border: 1px solid var(--documentBorder);
  margin-bottom: 1em;
}

.body {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}

.document {
  overflow: auto;
}

.modal {
  top: 0;
}
