body {
  margin: 0;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

a:visited {
  color: var(--primary);
}

button {
  font-size: var(--body);
}

button,
input,
textarea {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

button:focus {
  outline: none;
}

h1 { font-size: var(--h1); }
h2 { font-size: var(--h2); }
h3 { font-size: var(--h3); }
h4 { font-size: var(--h4); }
h5 { font-size: var(--h5); }
h6 { font-size: var(--h6); }

input {
  color: var(--inputText);
}

pre {
  text-align: left;
  white-space: pre-wrap;
  max-width: 750px;
  word-wrap: break-word;
  font-size: 0.75em;
}

@keyframes countdown {
  from { height: 100%; }
  to { height: 0%; }
}

:root {
  --h1: 2rem;
  --h2: 1.75rem;
  --h3: 1.5rem;
  --h4: 1.25rem;
  --h5: 1rem;
  --h6: 0.75rem;
  --body: 0.875rem;
  --subtitle: 0.75rem;
}

fieldset {
  border: 1px solid var(--divider);
}

fieldset legend {
  color: var(--secondary);
}
