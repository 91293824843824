.table {
  border-collapse: collapse;
  width: 100%;
}

.table tr {
  border-bottom: 1px solid var(--divider);
}

.table td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.link {
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--primary);
  padding: 0;
}
