.label {
  margin-bottom: 0.5em;
  color: var(--secondary);
  font-size: var(--body);
}

.errorLabel {
  margin-bottom: 0.5em;
  color: var(--error);
}
