.logo {
  margin-top: 1em;
}

.account {
  color: var(--highlight);
}

.dataSharing {
  color: var(--dataSharing);
}

.dataScience {
  color: var(--dataScience);
}

.feedback {
  color: var(--success);
}
