.container {
  display: flex;
  flex-direction: column;
  transition: height 0.2s ease-out;
}

.file {
  padding: 1em;
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--divider);
  border-radius: 4px;
  user-select: none;
}

.file:hover {
  background-color: var(--selectedRow);
}

.filename {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.progress {
  background-color: var(--fadedSand);
  flex: 1;
  margin-left: 1em;
  margin-right: 1em;
}

.progressAmount {
  background-color: var(--primary);
  color: var(--lightText);
  text-align: center;
  white-space: nowrap;
  padding: 4px;
  min-width: 5%;
  transition: width 1s ease-out;
  user-select: none;
  mix-blend-mode: difference;
}

.progressIcon {
  width: 24px;
  height: 24px;
  margin-right: 1em;
}

.cancelButton {
  background-image: url('../icons/close.svg');
  background-size: cover;
  width: 24px;
  height: 24px;
  border: 0;
  background-color: transparent;
  margin: 0;
  cursor: pointer;
}

.cancelButton:focus {
  outline: none;
}
