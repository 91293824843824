.input,
.textarea {
  font-size: var(--body);
  background: var(--white);
  border: 1px solid var(--divider);
  border-radius: 4px;
  padding: 1em;
}

.input:disabled {
  background-color: var(--selected);
}

.input:focus {
  border: 1px solid var(--primary);
  outline: none;
}

.error,
.error:focus {
  border: 2px solid var(--error);
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 582px;
  padding-bottom: 1em;
}

.formItem {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}

.formItem > * {
  flex: 1;
}

.formItem textarea {
  resize: none;
}

.narrow {
  width: 4em;
}

.actions {
  margin-top: 1em;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  height: 64px;
}

.errorMessage {
  font-size: var(--subtitle);
  color: var(--error);
}

.search {
  width: calc(100% - 5.5em);
  display: flex;
  align-items: center;
  background-image: url('./icons/search.svg');
  background-position: 0.5em 50%;
  background-repeat: no-repeat;
  padding-left: 2.5em;
  margin-bottom: 1em;
}

.search:focus {
  box-shadow: 0 0 4px 4px var(--primary);
}

.textarea {
  display: block;
  width: 90%;
}

.formAction {
  display: flex;
  flex-direction: row-reverse;
}
