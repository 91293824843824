.sortable {
  cursor: pointer;
  user-select: none;
}

.sortable:hover {
  color: var(--primary);
}

.sortIcon {
  fill: var(--dimText);
  margin-left: 0.5em;
  width: 1em;
  height: auto;
  vertical-align: middle;
  visibility: hidden;
}

.active > .sortIcon {
  visibility: visible;
}

.sortable:hover > .sortIcon {
  fill: var(--primary);
  visibility: visible;
}
