.breadcrumb {
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: var(--h3);
}

@media (min-width: 1024px) {
  .breadcrumb {
    flex: 1;
  }
}

.breadcrumb > a {
  color: var(--primary);
  display: flex;
}

.breadcrumb > a > span:hover {
  color: var(--secondary);
  fill: var(--secondary);
}

.breadcrumb > a:visited {
  color: var(--primary);
}

.home {
  width: 1.25em;
  height: auto;
  fill: var(--primary);
}

.home:hover {
  fill: var(--secondary);
}

.separator {
  fill: var(--label);
}
