.overlay {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--background);
  border: 2px dashed var(--primary);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
