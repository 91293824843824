
.details {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.details > h6 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.details > span {
  color: var(--secondaryHover);
}

.list {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 0.5em;
  color: var(--secondaryHover);
  padding: 0;
  padding-left: 1.5em;
}

.list > li {
  margin-bottom: 0.5em;
}

.settings div {
  margin-bottom: 10px;
}

.settings label {
  margin-right: 5px;
}
