.action {
  margin: 0 auto;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  font-weight: 500;
  padding: 1em;
  user-select: none;
}

.action:hover {
  border: 1px solid var(--primary);
}

.menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  border: 1px solid var(--divider);
  border-radius: 4px;
  background-color: var(--white);
  z-index: 1000;
}

.container {
  position: relative;
  width: min-content;
}

.actionItem {
  display: flex;
  align-items: center;
  padding: 0.75em 0.5em;
  white-space: nowrap;
  color: var(--text);
  font-size: var(--h5);
}

.actionItem > svg {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}

.actionItem:hover {
  color: var(--primary);
  background-color: var(--selected);
}
