.container {
  grid-area: a;
  background-color: var(--secondary);
  flex: 1;
  color: var(--lightText);
  overflow: hidden;
  height: 100%;
}

.column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: none;
}

.column::-webkit-scrollbar {
  display: none;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.navigationItem {
  width: 100%;
  height: 40px;
  background-color: var(--navigation);
  border: 0;
  color: var(--lightText);
  font-size: var(--body);
  cursor: pointer;
  padding: 0;
  margin: 0;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigationItem > svg {
  fill: var(--lightText);
  pointer-events: none;
  margin-right: 6px;
}

.navigationItem:focus,
.navigationItem:hover {
  background-color: var(--primary);
  color: var(--highlight);
}

.navigationItem:focus,
.navigationItem:hover > svg {
  fill: var(--highlight);
}

.copyright {
  color: var(--copyright);
  font-size: 11px;
  margin-top: 1em;
  margin-bottom: 1em;
  pointer-events: none;
}
