.actionButton {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-size: var(--h5);
  text-align: left;
  color: var(--secondary);
  padding: 0.5em;
  cursor: pointer;
  white-space: nowrap;
}

.actionButton > svg {
  margin-right: 6px;
  fill: currentColor;
}

.actionButton:focus {
  color: var(--primary);
}

.actionButton:hover {
  color: var(--primary);
}

.actionButton:disabled {
  opacity: 0.3;
}

.actionButton:disabled:hover {
  color: var(--text);
}

.dividerAfter {
  border-bottom: 1px solid var(--divider);
}
