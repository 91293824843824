.upn {
  color: var(--dimText);
  font-size: 0.75em;
  margin-left: 1em;
}

.selected {
  color: var(--white);
}

.menuText {
  margin-top: 0.5em;
}
