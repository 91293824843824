.header {
  height: 72px;
  pointer-events: none;
}

.header > * {
  pointer-events: auto;
}

.headerMenu {
  grid-area: b;
  justify-self: flex-start;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 0;
  margin: 1em;
  padding: 0;
  cursor: pointer;
  fill: var(--primary);
}

.headerMenu > img {
  width: 100%;
}

@media (min-width: 1024px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
