.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: fixed;
  top: 25%;
  left: 2em;
  right: 2em;
  bottom: auto;
  background-color: var(--background);
  border: 1px solid var(--divider);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  font-size: var(--body);
}

@media (min-width: 1024px) {
  .modal {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    min-width: 400px;
  }
}

.header {
  background-color: var(--secondary);
  color: var(--lightText);
  flex-shrink: 0;
  line-height: 1.5em;
  padding: 1.5em;
  font-weight: 900;
  user-select: none;
}

.header::first-letter {
  text-transform: capitalize;
}

.body {
  flex-grow: 1;
  padding: 1.5em;
  overflow-y: auto;
  line-height: 1.5em;
  max-height: 55vh;
}

.actions {
  border-top: 1px solid var(--divider);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  height: 64px;
  padding: 0 24px;
  border-radius: 0 0 4px 4px;
}

.list {
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}

.oldVersion {
  color: var(--dimText);
}

.close {
  position: absolute;
  top: 1.25em;
  right: 1em;
  border: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
}

.close > svg {
  width: 24px;
  height: 24px;
  fill: var(--lightText);
  pointer-events: none;
  opacity: 0.9;
}

.close:focus {
  outline: none;
}

.close:hover > svg {
  opacity: 1;
}

.breadcrumb {
  margin-top: 1em;
}

.selectArea {
  display: flex;
  flex-direction: column;
}

.error {
  color: var(--error);
  margin-top: 6px;
}
