.button {
  height: 32px;
  font-weight: 500;
  padding-left: 2em;
  padding-right: 2em;
  border: 0;
  user-select: none;
  cursor: pointer;
  border-radius: 16px;
}

.primary {
  background-color: var(--secondary);
  color: var(--lightText);
  margin-right: 6px;
  margin-bottom: 6px;
}

.primary:disabled {
  background-color: var(--disabled);
  color: var(--dimText);
}

.primary:focus {
  background-color: var(--primaryHover);
}

.primary:hover {
  background-color: var(--secondaryHover);
}

.primary:disabled:hover {
  background-color: var(--disabled);
  color: var(--dimText);
}

.secondary {
  background-color: transparent;
  color: var(--text);
  margin-right: 6px;
  margin-bottom: 6px;
}

.secondary:disabled {
  color: var(--dimText);
}

.secondary:hover {
  color: var(--primary);
}

.secondary:focus {
  outline: 1px solid var(--primary);
}

.new {
  display: flex;
  align-items: center;
  background-color: var(--primary);
  color: var(--lightText);
}

.new:hover {
  background-color: var(--primaryHover);
}

.new > svg {
  margin-right: 1em;
  fill: currentColor;
  pointer-events: none;
  opacity: 0.9;
}
