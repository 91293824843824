.container {
  grid-area: c;
  background-color: var(--fadedSand);
  overflow: hidden;
  height: 100%;
  font-size: var(--body);
}

.minimizer {
  position: absolute;
  top: 0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  user-select: none;
  color: var(--secondary);
}

.minimizer:hover {
  color: var(--secondaryHover);
}

.minimizer > svg {
  fill: currentColor;
}
