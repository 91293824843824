.link {
  background-color: var(--selected);
  border-radius: 4px;
  margin: 0.25em;
  width: 50%;
  height: 20px;
}

.link:first-of-type {
  margin-top: 1.5em;
}

.cellContent {
  background-color: var(--selected);
  border-radius: 4px;
  display: inline-block;
  height: 20px;
  width: 75%;
  flex: 1;
}

.text {
  background-color: var(--selected);
  border-radius: 4px;
  display: inline-block;
  height: 20px;
  width: 100px;
}

.textSmall {
  background-color: var(--selected);
  border-radius: 4px;
  display: inline-block;
  height: 14px;
  width: 50px;
}

.avatar {
  background-color: var(--selected);
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 0.5em;
}

.status {
  background-color: var(--selected);
  border-radius: 50%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 0.5em;
}

.icon {
  background-color: var(--selected);
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.iconSmall {
  background-color: var(--selected);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-left: 6px;
}

.image {
  background-color: var(--selected);
  width: 48px;
  height: 48px;
}

.centered {
  display: flex;
  align-items: center;
}
