.container {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted var(--secondary);
  width: fit-content;
}

.tooltip {
  width: 200px;
  visibility: hidden;
  background-color: var(--secondary);
  color: var(--lightText);
  text-align: center;
  border-radius: 4px;
  padding: 0.5em;
  position: absolute;
  z-index: 1;
  bottom: 250%;
  left: 0;
  user-select: none;
}

.tooltip::after {
  content: " ";
  position: absolute;
  bottom: -10px;
  left: 15%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--secondary) transparent  transparent transparent;
}

.container:hover .tooltip {
  visibility: visible;
}
