.fileInput {
  display: none;
}

.loadingIcon {
  height: 49px;
  margin-left: 12px;
}

.menu {
  position: absolute;
  background: var(--white);
  border: 1px solid var(--divider);
  border-radius: 4px;
  margin-top: 6px;
  right: 0;
}

.menu > * {
  width: 100%;
}
