.description {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1em;
}

.explanation {
  color: var(--secondaryHover);
  margin-bottom: 0.5em;
}

.flex {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.widget {
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.count {
  width: 100px;
  height: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
  margin-bottom: 0.5em;
  border-radius: 50%;
  user-select: none;
  font-size: var(--h1);
}

.links {
  display: flex;
  flex-direction: column;
  list-style-type: square;
}

.links > li {
  margin-bottom: 1em;
}
