.tag {
  display: inline-flex;
  align-items: center;
  background-color: var(--dataSharing);
  color: var(--text);
  font-size: var(--subtitle);
  margin: 2px;
  padding: 2px;
  padding-left: 6px;
  border-radius: 2px;
  user-select: none;
}

.removeTag {
  cursor: pointer;
  display: flex;
  align-self: center;
  margin: -2px;
  margin-left: 4px;
  padding: 2px;
  box-sizing: border-box;
}

.removeTag:hover {
  background-color: #ffbdad;
  fill: var(--error);
}

.removeTag > svg {
  stroke: currentColor;
}

.removeTag > svg:hover {
  background-color: #ffbdad;
  fill: #de350b;
}

.tag:focus {
  outline: none;
}
