.container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.container > svg {
  margin-right: 6px;
}

.status::first-letter {
  text-transform: capitalize;
}
