.refresh {
  margin-left: 0.5em;
  width: 1em;
  height: 1em;
  cursor: pointer;
  fill: var(--secondary);
}

.disabled {
  margin-left: 0.5em;
  width: 1em;
  height: 1em;
  cursor: default;
  opacity: 0.1;
  pointer-events: none;
}

.refresh:hover {
  fill: var(--primary);
}
