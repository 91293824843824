.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .page {
    overflow: hidden;
  }
}

.main {
  flex: 1;
  color: var(--secondary);
  text-align: center;
}
