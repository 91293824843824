.accountInfo {
  background-color: var(--primary);
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile {
  display: flex;
  padding: 0.5em;
  align-items: center;
  color: var(--lightText);
  user-select: none;
  font-size: var(--body);
}

.profile:hover {
  color: var(--highlight);
}

.profile > svg {
  fill: var(--lightText);
  margin-right: 0.5em;
}

.profile:hover > svg {
  fill: var(--highlight);
}

.username {
  word-break: break-all;
}
