.navigationRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1024px) {
  .navigationRow {
    margin-top: 1em;
    min-height: 32px;
  }
}

.noItems {
  display: block;
  margin-top: 2em;
}

.folderDescription {
  color: var(--secondary);
  margin-top: 1em;
  white-space: pre-wrap;
}

.searchResults {
  color: var(--primary);
  font-size: var(--h3);
}

.refresh {
  appearance: none;
  border: none;
  background: none;
  font-size: 2em;
  margin-left: 5px;
  padding: 0;
}

.refresh svg {
  margin: 0;
  top: 3px;
  position: relative;
}

.isRefreshing {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadMore {
  appearance: none;
  background: none;
  border: none;
  color: var(--secondary);
  cursor: pointer;
  height: 35px;
  width: 100%;
}

.loadMore:hover {
  color: var(--primary);
}

.loadingPage {
  text-align: center;
  width: 100%;
}
