.link {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.link > a {
  color: var(--primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link:hover > a {
  color: var(--secondary);
}

.link:hover > .newTab::after {
  position: absolute;
  display: inline-block;
  content: ' ';
  margin-left: 0.5em;
  background-image: url('./icons/newtab.svg');
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
}

.status {
  margin-left: 1em;
}
