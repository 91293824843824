.card {
  position: relative;
  width: 150px;
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: var(--secondary);
  margin: 0.5em;
  padding: 1em;
  border: 1px solid var(--divider);
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .card {
    margin-left: 0;
  }
}

.card > button {
  margin-bottom: 6px;
}

.card:hover {
  border: 1px solid var(--primary);
}

.background {
  background-image: url('../icons/gears.svg');
}

.icon {
  height: 48px;
}

.selected {
  background-color: var(--selectedRow);
}

.title {
  font-size: var(--h3);
  margin: 0;
}

.description {
  margin-bottom: 1em;
}

.unselect {
  position: absolute;
  top: 1em;
  right: 1em;
  border: 0;
  background-color: transparent;
}

.star {
  position: absolute;
  top: 1em;
  left: 1em;
  border: 0;
  background-color: transparent;
}

.star:focus {
  outline: none;
}

.status {
  position: absolute;
  right: 6px;
  bottom: 6px;
  font-size: var(--subtitle);
  text-transform: capitalize;
  color: var(--secondary);
  display: flex;
  align-items: center;
}

.status > svg {
  margin-left: 6px;
}

.disabled {
  cursor: default;
  opacity: 0.3;
}

.disabled:hover {
  border: 1px solid var(--divider);
}

.restrictedAccess {
  margin-top: 1em;
}
