.failed,
.offline,
.unavailable {
  fill: var(--statusError);
}

.available {
  fill: var(--statusAvailable);
}

.starting,
.stopping,
.updating {
  fill: var(--dataScience);
}
