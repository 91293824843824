.filename {
  cursor: pointer;
  color: currentColor;
}

.filename:visited {
  color: currentColor;
}

.nowrap {
  white-space: nowrap;
}

.previewLink {
  cursor: pointer;
  color: currentColor;
  border: none;
  background: none;
  text-align: left;
  appearance: unset;
  padding: 0;
}

.notStarred svg {
  visibility: hidden;
}

.filename:hover .notStarred svg {
  visibility: visible;
}

.star {
  top: 5px;
  left: 5px;
  position: relative;
}
