.frame {
  position: relative;
  padding: 4px 0 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  vertical-align: middle;
  fill: var(--lightText);
  text-align: left;
}

.content {
  will-change: transform, opacity, height;
  margin-left: 6px;
  padding: 0 0 0 14px;
  overflow: hidden;
}

.toggle {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  cursor: pointer;
  vertical-align: middle;
}

.nonToggle {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  vertical-align: middle;
}

.active {
  text-shadow: 0 0 1px var(--lightText);
}
