.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.description {
  margin-top: 1em;
  margin-bottom: 0;
}

.errorMessage {
  color: var(--error);
  font-size: var(--body);
}

.formItem {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  font-size: var(--body);
}

.formItem > * {
  flex: 1;
}

.formItem textarea {
  resize: none;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 582px;
}

.row {
  display: grid;
  grid-gap: 2em;
}

.left {
  grid-column: 1;
  display: flex;
  flex-direction: column;
}

.right {
  grid-column: 2;
  display: flex;
  flex-direction: column;
}

.error {
  color: var(--error);
  margin-top: 6px;
}

.marginBottom {
  margin-bottom: 6px;
}

.actions {
  margin-top: 1em;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  height: 64px;
}

.radioButton {
  display: flex;
  align-items: center;
}

.radioButton > input[type="radio"] {
  width: 24px;
  height: 24px;
  margin-right: 0.5em;
}

.radioButton > label {
  margin-right: 1em;
}

.radioButton > div {
  flex: 1;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox > input[type="checkbox"] {
  width: 24px;
  height: 24px;
  margin-right: 0.5em;
}

.dividerAfter {
  margin-bottom: 1em;
  padding-bottom: 2em;
  border-bottom: 1px solid var(--divider);
}

.infoText {
  font-size: var(--subtitle);
  margin-top: 1em;
}

.removeUser {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
}
