.overlay {
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--overlay);
  z-index: 999;
}

.menu {
  position: relative;
  top: 0;
  left: 0;
  width: 75%;
  height: 100%;
  background-color: var(--secondary);
  color: var(--lightText);
  display: flex;
  flex-direction: column;
}

.headerMenu {
  grid-area: b;
  justify-self: flex-start;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 0;
  margin: 1em;
  padding: 0;
  cursor: pointer;
  fill: var(--primary);
}

.headerMenu > img {
  width: 100%;
}
