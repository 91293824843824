.label {
  margin-top: 12px;
  margin-bottom: 6px;
  color: var(--label);
}

.errorLabel {
  margin-top: 12px;
  margin-bottom: 6px;
  color: var(--error);
}
