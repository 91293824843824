.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  background-color: var(--fadedSand);
  padding: 1em 2em 1em 2em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading > span {
  margin-top: 1em;
  font-size: var(--h4);
}

.loading > img {
  width: 2em;
  height: auto;
}

.iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.error {
  background-color: var(--fadedSand);
  color: var(--error);
  display: flex;
  flex-direction: column;
  padding: 1em 2em 1em 2em;
  align-items: center;
}
