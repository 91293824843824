.search {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 0.75em;
}

.search input {
  border: 1px solid var(--divider);
}

.search input:focus {
  border: 1px solid var(--primary);
  outline: none;
}

.close {
  cursor: pointer;
  background-color: var(--white);
  position: absolute;
  top: 14px;
  right: 18px;
}
