.list {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 1em;
  width: calc(100% - 5.5em);
}

.searchResults a {
  color: var(--white);
}

.list a {
  color: var(--lightText);
}

.list a:hover {
  color: var(--highlight);
}

.list a:visited {
  color: var(--lightText);
}

.list a:visited:hover {
  color: var(--highlight);
}

.notFound {
  text-align: center;
  margin-top: 1em;
}

.spaceAbove {
  margin-top: 2em !important;
}

.searchResults {
  margin-top: 1em;
  width: 90%;
}

.sectionTitle {
  font-size: 1.25em;
  margin-top: 0;
}

.searchFilesList {
  text-align: left;
}

.searchFilesList > div {
  margin-bottom: 0.1em;
}

.searchResultPath {
  font-size: 0.75em;
  margin-left: 1em;
}
