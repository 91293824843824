.page {
  width: 100%;
  height: 100vh;
  background-color: var(--secondary);
  background-image: url('../images/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--lightText);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 50%;
}

@media (min-width: 1024px) {
  .content {
    justify-content: center;
    margin-top: 0;
  }
}

.logo {
  height: 75px;
}

.cards {
  margin-top: 1em;
  height: 6em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cards > a:focus {
  outline: 1px solid var(--highlight);
}

.column {
  display: flex;
  flex-direction: column;
}

.card {
  color: var(--lightText);
  margin: 1em;
  display: flex;
  flex-direction: column;
}

.appName {
  font-size: var(--h3);
  margin-top: 1em;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card:hover > .appName {
  color: var(--highlight);
}

.appName > svg {
  fill: #fff;
  margin-right: 6px;
}

.card:hover > .appName > svg {
  fill: var(--highlight);
}

.actions {
  margin-bottom: 0.5em;
}
