.avatar {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
  margin-right: 0.5em;
  border-radius: 50%;
  user-select: none;
}
