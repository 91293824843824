:root {
  --primary: #00549e; /* orion blue */
  --secondary: #00234a; /* orion dark blue */
  --background: #fff; /* white */
  --text: #000; /* black */
  --lightText: #fff; /* white */
  --dimText: rgb(99, 114, 130);
  --divider: #d1d1c3; /* sand */
  --shadow: #a6bdeb;
  --selected: rgba(209, 209, 195, 0.25); /* sand */
  --fadedSand: rgba(209, 209, 195, 0.12); /* sand */
  --selectedRow: rgba(0, 108, 182, 0.1);
  --disabled: rgba(0, 84, 158, 0.1); /* orion blue */
  --black: #000; /* black */
  --white: #fff; /* white */
  --primaryHover: rgba(0, 84, 158, 0.9); /* orion blue */
  --secondaryHover: rgba(0, 35, 74, 0.8); /* orion dark blue */
  --label: #8e9089;
  --documentBorder: #c9c8c8;
  --overlay: rgba(255, 255, 255, 0.75);
  --error: #8b0000; /* dark red */
  --errorBg: #ffebe6; /* light pink */
  --errorFg: #580000; /* dark red */
  --statusAvailable: #648c2e;
  --statusError: #c62828; /* bright red */
  --success: #80c760; /* green */
  --successBg: #c4fcbc; /* light green */
  --successFg: #1a6700; /* dark green */
  --highlight: #faf44b; /* sun */
  --navigation: #003672;
  --inputText: #333;
  --copyright: #e7f2f6; /* light blue */
  --dataSharing: #9dd9e3;
  --dataScience: #ff983d;
}
