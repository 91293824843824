.active {
  padding-bottom: 2px;
  border-bottom: 1px solid var(--label);
}

.link {
  cursor: pointer;
}

.link:hover {
  color: var(--primary);
}
