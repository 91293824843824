.content {
  flex-grow: 1;
  font-size: var(--body);
  line-height: 1.4;
  padding: 1em 1.5em;
  min-height: 30px;
}

.countdown {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;
  will-change: height;
}

.dismissButton {
  cursor: pointer;
  flex-shrink: 0;
  opacity: 0.5;
  padding: 1em 1.5em;
  transition: opacity 150ms;
}

.dismissButton:hover {
  opacity: 1;
}

.dismissButton > svg {
  fill: currentColor;
  height: 16px;
  width: 16px;
}

.icon {
  position: relative;
  z-index: 1;
  width: 16px;
  height: 16px;
}

.iconWrapper {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  flex-shrink: 0;
  padding-bottom: 1em;
  padding-top: 1em;
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 30px;
}

.text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.toast {
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.175);
  display: flex;
  margin-bottom: 1em;
  width: 360px;
  will-change: height;
}

.error {
  background-color: var(--errorBg);
  color: var(--errorFg);
}

.errorIcon {
  background-color: var(--error);
}

.errorIcon > svg {
  fill: var(--errorBg);
}

.success {
  background-color: var(--successBg);
  color: var(--successFg);
}

.successIcon {
  background-color: var(--success);
}

.successIcon > svg {
  fill: var(--successFg);
}
