.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: var(--divider);
  font-size: var(--subtitle);
  font-weight: 700;
  margin-bottom: 0.5em;
  user-select: none;
  width: 100%;
}

.container svg {
  fill: currentColor;
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tags {
  margin-left: 1em;
  margin-right: 1em;
  text-align: left;
}
