.table {
  border-collapse: collapse;
  width: 100%;
}

.table td,
.table th {
  border-bottom: 1px solid var(--divider);
  padding: 0.5em;
}
